.pageLogin{

	display: flex;

}

.loginRight{

    position: absolute;
    top: 0;
    right: 0;
    height: auto;
    min-height: 100%;
    padding: 0px 60px 0px;
    color: #757575;
    background: #fff0;

}

.pageLoginForm {

    width: 400px;
    border-radius: 5px;
     
}

.pageLogin{

    background: url(http://b2.alunoativo.com.br/uploads/sistema/fundo_login.jpg?tamanho=555387) no-repeat center center fixed;
    background-size: cover;
    height: 100%;
    width:100%;
    position: absolute;

}
.page-brand-info{

    margin: 220px 100px 0 90px;

}

.loginBackground{ 

	width: 100%;
	background-color: #0000004f;

}

.page-brand-info{

	width: calc(100% - 630px);

}

@media (max-width: 767px){

	.loginRight{

	    width: 100%; 

	}

	.pageLoginForm{ width: 100%; }

	.loginBackground{ display: none; }

}

@media (max-width: 1000px){

	.page-brand-info{

		margin: 0px;
	    width: calc(100% - 520px);
	    text-align: center;
        position: relative;
    	top: 30%;
	    padding-left: 30px;
    	padding-right: 30px;

	}

}

.font-size-20 {
    
    font-size: 20px !important;

}

.access_class_room_login{ 
    
    background-color: #fff;
    margin-top: 15px;
    box-shadow: 0px 1px 3px -2px #000;
    border-radius: 25px 5px 5px 25px;

}
 
.name_login_qr_code_access{

    justify-content: center;
    align-items: center;
    padding-top: 8px;
    font-size: 20px;
    margin-left: 12px;
    font-weight: 300;

}

.buttton_access_class{

    height: 30px;
    margin-top: 10px;
    position: absolute;
    left: calc(100% - 70px);
    z-index: 999;
    top: -1px;

}

.card-hover-effect {
    -webkit-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
    -moz-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
    -o-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
    -ms-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
    transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
}

.c1{text-align: center; width: 120px; padding: 20px 0px 4px 0px; margin-right: 10px; background-color: #ffffff;cursor:pointer;}

.card-hover-effect:hover {
    box-shadow: 0px 12px 17px -7px rgba(0, 0, 0, 0.3);
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    -o-transition: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px);
}