.swal2-popup .swal2-title
{
    
    font-weight: 500 !important;
    font-size: 1.3rem !important;
    margin: 0 0 1rem !important;

}

.swal2-confirm{
    
    background: #fff !important;
    border-color: #ebedf2 !important;
    color: #212529 !important;
    border: 1px solid transparent;
    box-shadow: 0px 2px 8px -5px #000 !important;

}

.swal2-cancel{

    box-shadow: 0px 9px 6px -10px #000 !important;

}

