.container {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F6F8FC;
  flex-direction: column;

  p {
    font-size: 1.12rem;
    line-height: 1.75rem;
    font-weight: 300;
    font: 400 1rem "Nunito", sans-serif;
    color: #444;
  }
}

.containerContent {
  width: 100%;
  height: 100%;
  min-height: 19rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F6F8FC;
  flex-direction: column;

  p {
    font-size: 1.12rem;
    line-height: 1.75rem;
    font-weight: 300;
    font: 400 1rem "Nunito", sans-serif;
    color: #444;
  }
}
