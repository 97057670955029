






.form-control {

  height: 38px;
  padding: 0px 5px;
  color:#000;

}


.form-control.select2form{

  padding: 0px;
  border: 0px solid #dee2e6;
  
}

.form-control.select2form div{

  color: #000;

}

.form-control.select2form > div{

  border: 1px solid #dee2e6 !important;

}




/* inicio animação rápida */
.modal.fade.show{ transition: all 0 !important;}
.fade.show .modal-dialog { 
  
  -webkit-animation-duration: 0s !important;
  animation-duration: 0s !important;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: none;

}
.fade .modal-dialog { animation-duration: 0s !important; }
.modal.fade .modal-dialog { transition: all 0s !important;}

/* fim animação rápida */

.erroInputBase {
  color: red;
  margin-top: 5px;
}

.txt_wpp{

  background: white;
  border-radius: 11px;
  box-shadow: 0px 3px 9px -7px #000;
  
}

.bls{ display: flex; align-items: center;}

.bls_font{ margin-left: 5px; font-size: 13px; }

.forceDangerColor{

  background-color: #f5365c !important;

}

.bg_extra_vlitem{

  background-color:#eaeaea;

}

.opps_mtr .intn{ 

  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;

}
 
.slide-pane__overlay{ z-index: 9999; }

.opps_mtr{

  position: absolute;
  right: 10px;
  top: 0px;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;

}

.imgup {
  height: 100px !important;
  margin-bottom: 20px;
}

.bg-info {
  background: linear-gradient(-45deg, #7b142e, #d12646) !important;
}

.bg-gradient-info {
  background: linear-gradient(-45deg, #7b142e, #d12646) !important;
}

.paneUnitMove {
  background-color: #fff;
  border-radius: 10px;
  padding: 0px 15px !important;
}

.paneUnitMove .list-group-item {
  background-color: #fff0 !important;
  border: 1px solid #e9ecef00 !important;
}

.paneUnit2Move {
  background-color: #fff;
  border-radius: 0px;
  padding: 0px 15px !important;
}



.iconUnitDialog {
  max-height: 150px;
}

.paneLessonMove {
  border-radius: 15px;
}

.timeline-one-side:before {
  height: -webkit-fill-available;
}

.avatarIconCourse {
  background-color: #adb5bd00 !important;
  border-radius: 0px 0px 0px 0px !important;
}

.avatarIconCourse img {
  border-radius: 0px 0px 0px 0px !important;
}

.lessonInputTagSomentTag {
  background-color: #fff0;
}
.lessonInputTagSomentTag .tagInputClass {
  display: none;
}
.lessonInputTagSomentTag .removeClass::after {
  display: none;
}
.lessonInputTagSomentTag .removeClass {
  font-size: 18px;
  top: 49%;
}

.lessonInputTagSomentInput .tag-wrapper {
  display: none;
}

.rc-time-picker-input {
  padding: 0 !important;
  border: 1px solid #f8f9fe00 !important;
  color: #8898aa !important;
  font-size: 0.875rem !important;
}

.rc-time-picker-panel-inner {
  border: 1px solid #cccccc00 !important;
  box-shadow: 0 1px 5px #fdfdfd !important;
  color: #8898aa !important;
  padding: 0px;
  margin-top: 13px;
}

.rc-time-picker-panel-input {
  color: #8898aa !important;
  font-size: 0.875rem !important;
}

.rc-time-picker-panel-input-wrap {
  border-bottom: 1px solid #e9e9e9;
  padding: 2px 0px 10px 11px;
}

.navbar-brand {
  padding: 0px;
}

.navbar-vertical .navbar-brand-img,
.navbar-vertical .navbar-brand .logo_principal {
 
  max-height: 9999px !important;
  
}

.logoaqui img{

  

}

.navbar-brand {
  padding-left: 2px;
}

.paneUnit3Move .ql-editor {
  height: 96px;
}
.paneUnit3Move {
  background-color: #fff;
}
.paneUnit3Move svg {
  pointer-events: none;
}

.rna-wrapper div {
  z-index: 99999 !important;
}

.header-shadow {
  left: 19px;
  bottom: -30px;
  background: url("https://i.imgur.com/fh4ldeD.png") no-repeat top;
  height: 30px;
  z-index: 299;
}

.viewLesson {
  background-color: #fff;
}

.question-answer > :first-child {
  pointer-events: none;
}

.listLessonsTop {
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  box-shadow: 0px 11px 8px -11px #000;
  padding-left: 20px;
  border-radius: 0px 0px 15px 15px;
  width: max-content;
}

.dashboard_class_userList_user:first-child {
}

.buttonOpenDashBoard {
  position: absolute;
  left: calc(100% - 26px);
  top: 7px;
}

.lessonTop {
  width: 300px;
  height: 28px;
}

.inputViwerName_classroom {
  border: none;
}

.dashboard_class_user {
  border-top: 1px solid #ffffff00;
  position: relative;
}

.dashboard_class_user {
  border-top: 1px solid #e9ecef;
  padding-bottom: 3px;
  padding-top: 0px;
  padding-left: 10px;
}

.dashboard_class_user .avatar {
  width: 30px;
  height: 30px;
  margin-right: 3px !important;
}

.dashboard_class_userList_user {
  height: 55px;
  border-top: 1px solid #e9ecef;
  padding-bottom: 10px;

  display: -webkit-box;
  overflow: hidden;
  padding-left: 20px;
  position: relative;
  width: max-content;
}

.listTime_dashboard_class_room {
  width: 300px;
  position: relative;
}

.hidden_progress {
  display: none;
}

.outras_tentativas {
  color: #000;
  box-shadow: 0px 2px 2px -3px #000;
  padding-left: 0px;
  margin-bottom: 0px;
  font-size: 13px;
}

.outras_tentativas .date {
}

.outras_tentativas .timer {
  margin-right: 10px;
  float: right;
  color: #727c97;
}

.tot_register {
  font-size: 28px;
  color: #0000002e;
  float: right;
  margin-right: 10px;

  top: 5px;
  position: absolute;
  left: calc(100% - 55px);
  font-family: monospace;
}

.resto_progresso {
  margin-top: 10px;
}

.rrasta {
  pointer-events: none;
}

.p1 {
  position: absolute;
}

#qr {
  position: absolute;
  top: 80px;
  left: 385px;
  border-radius: 10px;
  box-shadow: 0px 2px 7px -1px #000;
  background-color: #fff;
  padding: 8px;
}

.padrao_cartao {
  position: relative;
  width: 600px;
  height: 340px;
}

.p1 i {
  background-image: linear-gradient(#931736, #d12646);
  color: #fff;
  font-size: 18px;
  padding: 9px;
  border-radius: 27px;
  box-shadow: 0px 1px 3px -1px #000;
}

.p1 font {
  margin-left: 16px;
  font-weight: 700;
  position: relative;
  top: -3px;
  color: #000;
}

.row_carduser {
  z-index: -999;
  opacity: 0;
  margin-top: 10px;
  width: 1260px;
  height: 340px;
  position: fixed;
  z-index: 0;
}

.congratulations {
  position: absolute;
  width: 300px;
  height: 200px;
  z-index: 15;
  top: 40%;
  left: 50%;
  margin: -100px 0 0 -150px;
}

.textViwerHtml {
  margin: 20px 20px 0px 20px;
}

.legenda_minimal_description {
  display: flex;
  margin-bottom: 5px;
  padding-left: 5px;
}

.legendaRelatorioTurma {
  margin-bottom: 5px;
  height: 150px;
  overflow-y: auto;
}

.dashboard_class_user_5 {
  padding-top: 13px;
}

.dashboard_class_user_2 {
  padding-top: 12px;
}

.legendaRelatorioTurma font {
  margin-left: 10px;
  margin-top: 1px;
  font-weight: 600;
}

.legendaRelatorioTurma .lengd {
  margin-right: 5px;
}

.lengd {
  cursor: help;
}

.listTime_dashboard_class_room_min {
  border-right: 0.6px solid #e6e6e6;
}

.lessonTop_minshadown {
  box-shadow: 0px 3px 0px -2.5px #d9d9d9;
  -webkit-backface-visibility: hidden;
}

.linha_icone {
  height: 0.6px;
  width: 1000px;
  top: 132px;
  position: absolute;
  background-color: #e8e8e8;
  display: none;
}

.nameUnitMinDashBoardClassroom {
  position: absolute;
  top: -4px;
  left: 2px;
}

.nameUnitMinDashBoardClassroom_icon {
  left: -4px;
  position: absolute;
  display: none;
}

.icon_progressunit_1 {
  height: 24px;
}
.calendarStudend {
  display: flex;
  margin-bottom: 10px;
  overflow: hidden;
  background-color: rgb(255, 255, 255);
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
}
.calendarStudendLesson {
  width: 200px;
}

.divCenterCalendarStudent {
  width: 200px;
}

.imgDivCenterCalendar {
  width: 25px;
}

.divCenterCalendarStudent.lesson {
  font-weight: bolder;
  font-size: 14px;
}

.divCenterCalendarStudent.lock_desc_lesson {
  margin-top: 10px;
}

.calendarStudendLesson {
  margin-top: 10px;
  margin-bottom: 5px;
}

.imgCenterCalendarA1 {
  max-height: 135px;
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.divIconLeftCalendarDash {
  border-radius: 5px 0px 0px 5px;
  padding-top: 38px;
  padding-left: 7px;
  background-color: #eff1fc;
  padding-right: 7px;
  box-shadow: 2px 0px 9px -7px #000;
  cursor: pointer;
  position: absolute;
  height: calc(100% - 10px);
  z-index: 1;
}

.divIconRightCalendarDash {
  border-radius: 0px 5px 5px 0px;
  padding-top: 38px;
  padding-left: 7px;
  background-color: #eff1fc;
  padding-right: 7px;
  box-shadow: -2px 0px 9px -7px #000;
  cursor: pointer;
  position: absolute;
  height: calc(100% - 10px);
  left: calc(100% - 20px);
  z-index: 1;
}

.tabCimaImportXLSX div {
  font-size: 16px;
  width: 120px;
  margin-right: 20px;
}

.tabCimaImportXLSX input {
  width: 110px;
}

.div_tab_importxlsx td {
  background-color: #fff;
}

.div_tab_importxlsx input {
  border: none;
}
.div_tab_importxlsx select {
  border: none;
}

.table_importXLXS td {
  padding: 4px;
}

.table_importXLXS td {
  padding-left: 5px !important;
  padding-right: 0px !important;
}

.table_importXLXS tr:hover td {
  background-color: #f7f7f7;
}

.div_tab_importxlsx th {
  padding-left: 5px !important;
  padding-right: 0px !important;
}

.navbar-vertical.navbar-expand-xs {
  max-width: 55px;
}

@media (min-width: 1200px) {
  .sidenav:hover {
    max-width: 250px !important;
  }
}

.g-sidenav-hidden:not(.g-sidenav-show) .sidenav .nav-link-text {
  display: block !important;
}

.sidenav:hover .navbar-brand {
  display: block;
}

.navbar-vertical.navbar-expand-xs .navbar-nav .nav-link {
  padding: 0.675rem 1.35rem;
}

.navbar-vertical.navbar-expand-xs.fixed-left + .main-content {
}

.fastShow {
  animation-duration: 0s !important;
}

.dashboard_class_user:hover {
  background-color: #f1f1f1;
}

.dashboard_class_user:hover .inputViwerName_classroom {
  background-color: #f1f1f1;
}
.inputViwerName_classroom {
  background-color: #f7fafc;
}
.box_left_detailed {
  padding-right: 0px;
}

.dialogfastuserchart .apexcharts-legend {
  top: 37px !important;
}

.select_charts_fast .dropdown {
  width: 230px;
  text-align: justify;
  z-index: 999;
}

.select_charts_fast {
  text-align: center;
}

.select_charts_fast .dropdown-heading {
  border: 1px solid rgb(255, 255, 255) !important;
  box-shadow: 0px 1px 4px -4px #000 !important;
}

.select_charts_fast .dropdown-content {
  border-color: #fff !important;
  box-shadow: 0px 1px 4px -4px #000 !important;
}

.chartCol12 {
  margin-bottom: 30px;
}

.iframeEngine {
  position: absolute;
  top: 0px;
  z-index: 999;
}

.txtLessonTop {
  white-space: nowrap;
  position: relative;
  width: 50px;
  transform: rotate(-52deg);
  height: 28px;
  top: 20px;
}

.txtLessonTop:last-child {
  margin-right: 90px;
}

.login_mais {
  height: 14px !important;
  margin-left: 5px;
  margin-right: 5px;
}

.logologin_super,
.logologin_cs {
  height: 65px;
}

.sidenav-header .login_mais {
  height: 12px !important;
}

.sidenav-header .logologin_super {
  height: 30px !important;
}

.sidenav-header .logologin_cs {
  height: 30px !important;
}

.custom-text-prop {
  width: "100%";
}


.fontButton{
  
  font-size: .875rem;
  color: #adb5bd;
  margin: 0 .25rem;
  cursor: pointer;

}

.fontButton:hover{ color: #919ca6; }

.inputClientListField{

    width: 90%;
    border: none;
    box-shadow: 0px 1px 2px -2px #000;
    margin-left: 6px;
    border-radius: 8px;
    padding-left: 5px;

}

.acustomfile{

  color: #8898aa;
  background-color: #fff;
  border: 1px solid #dee2e6;
  padding: 0.625rem 0.75rem;

}

.inputSearchClient{

  margin-left: 0.5em;
  display: inline-block;
  width: auto;

}

.nav_client_mudar{

  border-bottom: 0px solid #dee2e6;
 
}

.nav_client_mudar li{ 
  
  border-radius: 10px 10px 0px 0px !important;
  padding:0px;
  margin-right: 10px;
  font-size: 14px;
  padding: 5px;
  height: 48px;

}

.nav_client_mudar button{ 
  margin-top: 3px; 
  cursor:pointer; 
  border:none; 
  background-color: #00000000; 
  color:#525f7f;
  margin-left: 10px;
  margin-right: 10px;
}

.nav_client_mudar li{ 
  
  background-color: #ececec; 
  cursor:pointer;
  box-shadow: 0px 0px 4px -2px #000;

}

.tabscima_extra{

  position: relative;
  left: 25%;
  margin-left: 5px;
  width: calc(100% - 25%);

}

.nav_client_mudar font{

  display: block;
  padding: 0.25rem 0.75rem;

}

.nav_client_mudar li.active{ 
 
  background-color: #fff;

}

.nav_client_mudar li.active button{ 

  color: #5e72e4;
  
}

.div_parentage{

  box-shadow: 0px 1px 5px -4px #000;
  padding: 15px 0px 20px 0px;
  border-radius: 10px;

}

tr:hover  { box-shadow: 0px 0px 8px -6px #9711eb; }

.discontou_inp{

  box-shadow: 0px 0px 21px -9px #000;
  width: 32px;
  background-color: #eaeaea;
  text-align: center;
  border-radius: 15px;
  border:none;

}

.input_view_data_matricula{

  border: none;
  color: #8898aa !important;
  font-weight: 200;

}

.td_discount{display: none;}

.gerar_separado{ display: none;  border-bottom: 0px solid #e9ecef00 !important; }
.ignore_item{ display: none;  border-bottom: 0px solid #e9ecef00 !important; }

 
.select_custom_separated{

  border: none;
  border-radius: 5px;
  box-shadow: 0px 1px 1px -1px #000;
  padding-left: 5px;
  padding-right: 5px;
  color: #525f7f;
  cursor:pointer;

}


.display_none{ display: none; }
.display_none_important{ display: none !important; }

.divCimaTabsClient{

  position: absolute;
  width: 100%;
  text-align: right;
  padding-right: 15px;
  top: 12px;

}

.capt{ text-transform: capitalize; }


.box_alunos_matriculas{

}

.dropZoneClient{

  box-shadow: 0px 0px 13px -10px #9524e6;
  margin: 34px 20px 34px 0px;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 10px;
  padding-right: 10px;
  color: #525f7f;
  font-weight: 200;
  text-align: center;
  cursor: pointer;

}

.file_cc img{ height: 60px; margin-bottom: 10px;}

.listFiles{

  position: relative;
  display: grid;
  grid-gap: 0;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  top: -30px;
 
}

.searchFile{

  /*margin-bottom: 30px;*/
  border-radius: 5px;
  box-shadow: 0px 1px 6px -5px #000;

}

.tb_file_aa{

  padding-left:1.5rem !important;
  padding-right:1.5rem !important;

}

.tb_file_left{

  padding-left: 50px;
  padding-top: 30px;
  
}

.file_center{

  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #edf2f9;
  border-radius: 4px;
  padding: 10px;
  margin-right: 15px;
  margin-bottom: 15px;
  height: 134px;

}

.file_center:hover{

border-color: transparent;
box-shadow: 0px 7px 25px rgba(18,38,63,0.1);

}


.vertical-timeline-element-date{ opacity: 1 !important; }

.vertical-timeline:before{

  background-color: #ddd;

}

.show_name_annotation{


    color: rgb(0, 0, 0);
    position: absolute;
    top: 66px;
    background-color: #fff;
    display:none;
     
    border-radius: 6px;
    box-shadow: 0px 1px 3px -2px #000;
    padding: 0px;
    width: calc(100% + 9px);
    text-align: center;
    padding-top: 6px;
    padding-bottom: 5px;
    font-size:11px;
}

.vertical-timeline-element-icon:hover .show_name_annotation{ display:block; }

.vertical-timeline-element-date{ margin-top:10px; }

.chamativo{ box-shadow: 0px 0px 12px -7px #000; }



.faturas_inner input{

height: 30px;

}

.faturas_inner .input-daterange .form-group{
 
  margin-bottom: 0px;

}

.faturas_inner .input-daterange{

  padding-top: 8px;

}

.apexcharts-legend-text{

  font-family: inherit !important;
  font-size:13px !important;

}

.td_data_registration input{ height: 20px !important;}

.rdtPicker{ position: absolute; z-index: 9999 !important;}

.opacity_1{

  opacity: 0.6;

}

.opacity_1:hover{

  opacity:1 !important;

}

@media print
{    

 

    .no-print, .no-print *
    {
        display: none !important;
    }

    .extra_info_registration_print{

      display: block !important;
      
    }

    .print_ttt_invoice{

      top: 0px;
      position: fixed;

    }

    .input_item{ border: 0px solid #dee2e6; }

    nav{ display: none !important; }




    /* Para imprimir o contrato */
    .print_pdf{

      max-width: calc(100%) !important;
      margin:0px !important;

    }

    .modal-dialog{

      margin:0px !important;
      padding: 0px !important;

    }

    .modal-backdrop{

      display: none !important;

    }

    .modal-body{

      padding: 0px !important;
      
    }

    .modal-dialog-centered{

      align-items: inherit !important;

    }

}





.extra_info_registration_print{

  display: none;
  margin-top: 0px;
  margin-bottom: 15px;
  margin-left: 20px;

}

.extra_info_registration_print font{ margin-left: 5px; font-weight: 700;}

.div_rodinha_pdf div{

  position: relative;
  top: 2px;
  left: 2px;
  
}


.swal2-popup.swal2-toast .swal2-title{

  padding-left: 10px;
  padding-top: 15px;

}

.menu_left_ddd .nav-item{

  background-color: #fff;

}

.menu_left_ddd .nav-item.active{

  box-shadow: 0px 16px 13px -18px #000;
  transition: background 1s cubic-bezier(0.16, 0.98, 0.58, 1) !important;
  z-index: 999;
  position: relative;

} 
 
.menu_left_ddd .nav-item.active i{ 
  
  color:#fff !important;

}

.menu_left_ddd .nav-item .nav-link-text{

  font-weight: 600;
  
}


.menu_left_ddd .nav-item.active .nav-link-text{

  color: #fff;

}

.navbar-vertical.navbar-expand-xs.fixed-left{

  border-width: 0 0px 0 0;

}

li.active.nav-item > a{

  font-weight: 600 !important;
  color: #fff !important;
  
}

.navbar-vertical.navbar-expand-xs .navbar-nav > .nav-item > .nav-link.active{

  background-color: #9900ef;
  margin-right: 0px;
  margin-left: 0px;
  box-shadow: 0px 16px 13px -18px #000;
  border-radius: 0px;
  color: #fff;
  padding-left: 21px;


}

.navbar-vertical.navbar-expand-xs .navbar-nav > .nav-item > .nav-link.active > i{ color: #fff !important;}

.navbar-vertical .navbar-nav .nav-link > i{     min-width: 34px !important; }

.not_aloowed_button{

  cursor: no-drop !important;
  opacity: 0.5;

}