@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --primary-color: #ed2124;
  --secondary-color: #428ce8;
  --text-primary: #1b212f;
  --text-secondary: #505050;
  --text-third: #707070;

  --primary-color-700: #f36466;
  --primary-color-600: #f04548;
  --primary-color-500: #f69092;
  --primary-color-400: #f47172;
  --primary-color-50: #fee9ea;
  --primary-color-100: #fee9ea;

  --secondary-color-700: #7bafef;
  --secondary-color-600: #8ebaf1;
  --secondary-color-500: #a1c6f4;
  --secondary-color-400: #b3d1f6;
  --secondary-color-50: #f5f9fe;
  --secondary-color-100: #edf4fd;

  --success-color: #3ed498;
  --success-color-500: #7cc9aa;
  --success-color-100: #e4fff5;

  --warning-color: #fcc600;
  //Image Glitch
  --color-text: #fff;
  --color-bg: #000;
  --color-link: #f9d77e;
  --color-link-hover: #fff;
  --color-info: #efc453;
  --glitch-width: 100vw;
  --glitch-height: 100vh;
  --gap-horizontal: 10px;
  --gap-vertical: 5px;
  --time-anim: 4s;
  --delay-anim: 2s;
  --blend-mode-1: none;
  --blend-mode-2: none;
  --blend-mode-3: none;
  --blend-mode-4: none;
  --blend-mode-5: overlay;
  --blend-color-1: transparent;
  --blend-color-2: transparent;
  --blend-color-3: transparent;
  --blend-color-4: transparent;
  --blend-color-5: #af4949;
}

html {
  width: 100%;
  height: 100%;
}

body,
input,
textarea,
select,
button,
span {
  font: 400 1rem "Nunito", sans-serif;
}
// font-family: 'Nunito', sans-serif;
// font-family: 'Montserrat', sans-serif;
button {
  cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

.swiper-pagination-bullet-active {
  background: var(--primary-color) !important;
}

.swiper-pagination-bullet {
  transition: all ease-in-out 0.2s;
}

.swiper-pagination {
  bottom: 0rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.containerSwiperListRow {
  position: relative;

  .swiper-horizontal {
    width: 100%;
  }

  .swiper-wrapper {
    width: 100%;
  }
}

.swiper-button-disabled {
  background-color: #FFF !important;
  color: var(--primary-color-500) !important;
}

@media (max-width: 1080px) {
  html {
    font-size: 93.75%;
  }
}

@media (max-width: 720px) {
  html {
    font-size: 87.5%;
  }
}
